textarea
    $padding: 1.5rem

    width: 30rem
    height: 10rem
    font-family: $font-main
    font-size: 1.6rem
    padding: $padding
    outline: none
    border: 1px solid $black
    background-color: $white

    resize: vertical

    border-radius: 0 !important
    -webkit-appearance: none !important

    &:focus
        border: .1rem solid $orange

    &--testSize
        height: 300px
        width: 400px
