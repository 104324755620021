select
    display: block
    font-size: 1.5rem
    font-weight: 400
    outline: none

    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

    background-color: transparent
    background-image: none
    border: 1px solid $white
    color: $black

    min-width: 20rem
    padding: 1rem 2.5rem

    vertical-align: center