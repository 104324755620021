@import breakpoints

//Layout Standard Width
$width: 100%
$standard_max_width: 110rem

//Layout Standard Height
$min_height: 100vh
$max_height: 90rem
$height: 100vh

// Header bar height
$header_height: 7.5rem

// Margins
$section_margin: 7.5rem

// Colors
$white: #FAF8F8
$black: #120700
$dark_grey: #7E7E7E

$red: #b53612
$green: #63930F
$purple: #CCD0F6
$yellow: #FFFC00
$hero_green: #B9D49A
$orange: #d1a64a

// Small grid percent
$grid_size: 42.5%

// Fonts
@font-face
  font-weight: 400
  font-family: 'comforta'
  src: url('../fonts/comfortaa/Comfortaa-VariableFont_wght.ttf') format("truetype")

@font-face
  font-weight: 300
  font-family: 'comfortaLight'
  src: url('../fonts/cofley/cofley-light.ttf') format("truetype")

$font-main: 'comforta'
$font-title: 'comfortaLight'
